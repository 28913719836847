import { defineMessages } from "react-intl";

export const globalTranslations = defineMessages({
  share: { id: "generics-share", defaultMessage: "Partager" },
  login: { id: "generics-login", defaultMessage: "Se connecter" },
  logout: { id: "generics-logout", defaultMessage: "Se déconnecter" },
  signup: { id: "generics-signup", defaultMessage: "S'inscrire" },
  continue: { id: "generics-continue", defaultMessage: "Continuer" },
  cancel: { id: "generics-cancel", defaultMessage: "Annuler" },
  profile: { id: "generics-profile", defaultMessage: "Mon profil" },
  send: { id: "generics-send", defaultMessage: "Envoyer" },
  save: { id: "generics-save", defaultMessage: "Sauvegarder" },
  search: { id: "generics-search", defaultMessage: "Rechercher" }
});

export const bookingTranslations = defineMessages({
  checkIn: { id: "generics-check-in-label", defaultMessage: "Arrivée" },
  checkOut: { id: "generics-check-out-label",  defaultMessage: "Départ" },
  period: { id: "generics-period", defaultMessage: "Période" },
  periodPlaceholder: { id: "generics-check-in-out-placeholder", defaultMessage: "Ajouter des dates" },
  price: { id: "generics-price", defaultMessage: "Prix" },
  nightsPeriod: { id: "generics-selection-period", defaultMessage: "{nightsCount, plural, =1 {# nuit} other {# nuits}}" },
  total: { id: "generics-total", defaultMessage: "Total" },
  priceOnDemand: { id: "generics-price-on-demand", defaultMessage: "Sur demande" },
  fullPriceOnDemand: { id: "generics-full-price-on-demand", defaultMessage: "Prix sur demande" },
  onDemandOnly: { id: "generics-on-demand-only", defaultMessage: "Sur demande uniquement" },
  discount: { id: "generics-discount-valued", defaultMessage: "Réduction de {val}" },
  discountPeriod: { id: "generics-discount-period", defaultMessage: "À partir du {start} au {end}" },
  book: { id: "generics-booking-button", defaultMessage: "Réserver maintenant" },
  availabilities: { id: "generics-availabilities-button", defaultMessage: "Voir les disponibilités" },
  benefits: { id: "generics-benefits", defaultMessage: "Prestations" },
  customerCharge: { id: "generics-customer-charge", defaultMessage: "Frais de service" },
  customerChargeTooltip: { id: "generics-customer-charge-explanation", defaultMessage: "Ces frais sont liés aux coûts d'encaissement en ligne via le prestataire de paiement Stripe." }
});

export const cartTranslations = defineMessages({
  backHome: {id: "cart-process-end-back-button", defaultMessage: "Retour à la page d'accueil"}
});

export const featureTranslations = defineMessages({
  wifi: { id: "generics-features-wifi", defaultMessage: "WIFI" },
  tv: { id: "generics-features-tv", defaultMessage: "TV" },
  washingMachine: { id: "generics-features-washing-machine", defaultMessage: "Lave-linge" },
  dishwasher: { id: "generics-features-dishwasher", defaultMessage: "Lave-vaisselle" },
  fireplace: { id: "generics-features-fireplace", defaultMessage: "Cheminée" },
  parking: { id: "generics-features-parking", defaultMessage: "Parking" },
  balcony: { id: "generics-features-balcony", defaultMessage: "Balcon" },
  terrace: { id: "generics-features-terrace", defaultMessage: "Terrasse" },
  pool: { id: "generics-features-pool", defaultMessage: "Piscine" },
  sauna: { id: "generics-features-sauna", defaultMessage: "Sauna" },
  smoker: { id: "generics-features-no-smoking", defaultMessage: "Non-fumeur" },
  pet: { id: "generics-features-pet-compliant", defaultMessage: "Animaux admis" },
  disabledPerson: { id: "generics-features-disabled-compliant", defaultMessage: "Accès pour personnes handicapées" },
  smokerAlarm: { id: "generics-features-smoke-alarm", defaultMessage: "Détecteur de fumée" },
  carbonAlarm: { id: "generics-features-carbon-alarm", defaultMessage: "Détecteur de monoxyde de carbone" }
});

export const agencyTranslations = defineMessages({
  contactLabel: { id: "generics-contact", defaultMessage: "Contact" },
  contact: { id: "generics-agency-contact", defaultMessage: "Contacter l'agence" },
  about: { id: "generics-agency-about", defaultMessage: "À propos" }
});

export const formTranslations = defineMessages({
  firstNamePlaceholder: { id: "generics-first-name-placeholder", defaultMessage: "Prénom" },
  lastNamePlaceholder: { id: "generics-last-name-placeholder", defaultMessage: "Nom" },
  emailPlaceholder: { id: "generics-email-placeholder", defaultMessage: "E-mail" },
  phonePlaceholder: { id: "generics-phone-placeholder", defaultMessage: "Téléphone" },
  streetPlaceholder: { id: "generics-street-placeholder", defaultMessage: "Rue" },
  streetNumberPlaceholder: { id: "generics-street-number-placeholder", defaultMessage: "N°" },
  streetComplementPlaceholder: { id: "generics-street-complement-placeholder", defaultMessage: "Adresse complémentaire" },
  zipPlaceholder: { id: "generics-zip-placeholder", defaultMessage: "NPA" },
  cityPlaceholder: { id: "generics-city-placeholder", defaultMessage: "Localité" },
  countryPlaceholder: { id: "genrics-country-placeholder", defaultMessage: "Sélectionnez un pays" },
  messagePlaceholder: { id: "generics-message-placeholder", defaultMessage: "Dites-nous comment nous pouvons vous aider." },
  emptyError: { id: "generics-form-empty-error", defaultMessage: "Cette information est requise." },
  mailError: { id: "generics-form-mail-error", defaultMessage: "Cet e-mail n'est pas valide." },
  phoneError: { id: "generics-form-phone-error", defaultMessage: "Ce numéro n'est pas valide. Veuillez respecter le format international (exemple: +41 xx xxx xx xx)." },
  sendWithBookingPeriod: { id: "generics-form-bookingperiod-label", defaultMessage: "Souhaitez-vous transmettre la période de réservation sélectionnée ?" },
  minError: { id: "generics-form-min-error", defaultMessage: "Doit contenir au moins {value, plural, =1 {# caractère} other {# caractères}}." },
  optionError: { id: "generics-form-option-error", defaultMessage: "Veuillez sélectionner cette option." },
  sent: { id: "generics-form-send-success", defaultMessage: "Votre message a été envoyé avec succès." },
  saved: { id: "generics-form-save-success", defaultMessage: "Vos données ont été sauvegardées." },
  failed: { id: "generics-form-send-error", defaultMessage: "Une erreur est survenue à l'envoi de votre message." },
  failedSave: { id: "generics-form-save-error", defaultMessage: "Une erreur est survenue à la sauvegarde." }
})
