import { gql } from "@apollo/client";

export const CART_BADGE_GQL_DATA = gql`
  query CartBadge($sessionId: String, $userId: String) {
    cart(sessionId: $sessionId, userId: $userId) { cartId itemsCount }
  }
`;

export const FULL_CART_GQL_DATA = gql`
  query UserCart($sessionId: String, $userId: String) {
    cart(sessionId: $sessionId, userId: $userId) {
      cartId
      itemsCount
      items {
        ... on RentalAccommodationCartItem {
          itemId title description image expirationDate period price { bare discount options { type amount } amount downPayment }
        }
      }
      fullPrice { amount discount downPayment }
    }
  }
`;
