import React from "react";
import { useIntl } from "react-intl";
import { IconInfoSquareRoundedFilled } from "@tabler/icons-react";
import { classNames } from "@ct-react/core";
import { bookingTranslations } from "../../../i18n/sharable-defs";
import { DataProps } from "../../../tools/components";
import "./book-price-resume.scss";
import Price from "../../common/price";
import Tooltip, { InnerTooltip } from "../../common/tooltip";
import { ExtraType, FormattedBookingOption, isBookingPrice } from "@ct-react/calendar";
import { useLocaleFormatter } from "@ct-react/locale";

const BookPriceResume = (
  {
    className,
    data
  }: DataProps<FormattedBookingOption>) => {

  const intl = useIntl();
  const { print } = useLocaleFormatter();

  const wrapperClasses = classNames("rla-book-price-resume", className);

  return (
    <div className={wrapperClasses}>

      {(isBookingPrice(data.price)) &&
        <>
          <dl className="price-list">
            <dt>
              {intl.formatMessage(bookingTranslations.nightsPeriod, {
                nightsCount: data.period.end.diff(data.period.start, "day")
              })}
            </dt>
            <dd><Price data={data.price}/></dd>
          </dl>
          {data.price.detailed.extras.map((ex: any, i) =>
            <dl key={i} className="price-list">
              <dt>
                {(() => {
                  switch (ex.type) {
                    case ExtraType.CustomerCharge:
                      return (
                        <Tooltip placement="bottom">
                          <InnerTooltip.Trigger as="span" className="tooltip-more">
                            {intl.formatMessage(bookingTranslations.customerCharge)}
                            <IconInfoSquareRoundedFilled />
                          </InnerTooltip.Trigger>
                          <InnerTooltip.Content>{intl.formatMessage(bookingTranslations.customerChargeTooltip)}</InnerTooltip.Content>
                      </Tooltip>);
                    default:
                      return null;
                  }
                })()}
              </dt>
              <dd>{print.price(ex.amount)}</dd>
            </dl>)
          }
        </>
      }
      <dl className="price-list total">
        <dt>{intl.formatMessage(bookingTranslations.total)}</dt>
        <dd>
          {data.price === "onDemand"
            ? intl.formatMessage(bookingTranslations.fullPriceOnDemand)
            : <Price data={data.price} total={true} />
          }
        </dd>
      </dl>

    </div>);

};

export default BookPriceResume;
