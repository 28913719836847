import React, {createContext, ElementType, HTMLProps, PropsWithChildren, useContext, useMemo, useState} from "react";
import {
  autoUpdate,
  flip,
  FloatingFocusManager, FloatingPortal,
  offset,
  Placement,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useMergeRefs
} from "@floating-ui/react";
import {OffsetOptions} from "@floating-ui/core/src/middleware/offset";
import {FlipOptions} from "@floating-ui/dom";
import {IconX} from "@tabler/icons-react";
import {classNames} from "@ct-react/core";
import {Button} from "./minimals";
import "./tooltip.scss";

type FloatingProps = {
  placement?: Placement;
  offset?: OffsetOptions;
  flip?: FlipOptions;
}

type TooltipProps = Required<PropsWithChildren> & FloatingProps;
type TooltipTriggerProps = HTMLProps<HTMLElement> & { as?: ElementType };
type TooltipContentProps = HTMLProps<HTMLElement> & { title?: string; };

const useFloatingTooltip = (props: Required<FloatingProps>) => {

  const [ open, setOpen ] = useState(false);

  const floating = useFloating({
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    placement: props.placement,
    middleware: [
      offset(props.offset),
      flip(props.flip)
    ]
  });

  const interactions = useInteractions([
    useClick(floating.context),
    useDismiss(floating.context)
  ]);

  return useMemo(() => ({ open, setOpen, floating, interactions }), [ open, floating, interactions ]);

}

const TooltipContext = createContext<ReturnType<typeof useFloatingTooltip> | undefined>(undefined);

const useTooltipContext = () => {
  const context = useContext(TooltipContext);
  if (!context) throw new Error("Tooltip component must be wrapped by tooltip context");
  return context;
}

const TooltipTrigger = (
  {
    as: Tag = "span",
    className,
    children,
    ...props
  }: TooltipTriggerProps) => {

  const context = useTooltipContext();
  const ref = useMergeRefs([ context.floating.reference, (children as any).ref ]);
  const wrapperClasses = classNames("r-tooltip-trigger", className);

  return (
    <Tag ref={ref}
         {...context.interactions.getReferenceProps(props)}
         className={wrapperClasses}>{children}</Tag>);

}

const TooltipContent = (
  {
    title,
    children,
    className,
    style,
    ...props
  }: TooltipContentProps) => {

  const context = useTooltipContext();

  const closerRendering = useMemo(() => (
    <Button type="button"
            className="closer rounded-icon link"
            onClick={() => context.setOpen(false)}>
      <IconX />
    </Button>), []);

  const wrapperClasses = classNames("r-tooltip", className, { inline: !title });

  return (
    <>
      {context.open &&
        <FloatingFocusManager context={context.floating.context}>
          <section ref={context.floating.floating}
                   {...context.interactions.getFloatingProps(props)}
                   className={wrapperClasses}
                   style={{ position: context.floating.strategy, top: context.floating.y ?? 0, left: context.floating.x ?? 0 }}>

            {!title &&
              <>
                {closerRendering}
                {children}
              </>
            }

            {!!title &&
              <>
                <header>
                  {closerRendering}
                  {title}
                </header>
                {children}
              </>
            }
          </section>
        </FloatingFocusManager>
      }
    </>)

}

const Tooltip = (
  {
    children,
    placement = "top-start",
    offset = 10,
    flip = { crossAxis: true }
  }: TooltipProps) => {

  const floating = useFloatingTooltip({ placement, offset, flip });
  return (<TooltipContext.Provider value={floating}>{children}</TooltipContext.Provider>);

}

export default Tooltip;

export namespace InnerTooltip {
  export const Trigger = TooltipTrigger;
  export const Content = TooltipContent;
}
