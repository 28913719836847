import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { defineMessages, useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { AnyPeriod, classNames, toGQLPeriod, useAppConfig, useIsMounted } from "@ct-react/core";
import { useLocaleNavigate } from "@ct-react/locale";
import { useVisitor } from "@ct-react/visitor";
import { buildArticleUrl } from "@shared/urls";
import { CustomViewType } from "@shared/models/app-context";
import {
  ADDRESS_FRAGMENT,
  ALL_FEATURES_FRAGMENT,
  COST_DESCRIPTIONS_FRAGMENT,
  MERCHANT_CONTACT_FRAGMENT
} from "@shared/gql/fragments";
import { useDisplayUntilTablet } from "../tools/breakpoints";
import {
  parseOpenArticleQueryParams,
  parseRefIdQueryParam,
  rebuildOpenArticleQueryParams
} from "../tools/search-params/open-article";
import { Comment as CommentModel } from "../models/comment";
import { AddCartItem, AddCartItemOption } from "../models/cart";
import NotFound from "./not-found";
import Wrapper from "../components/layout/wrapper";
import SeoHelmet from "../components/seo-helmet";
import { MapContainer, MapMarker } from "../code-splitting/map-implement";
import Title from "../components/article/parts/title";
import Description from "../components/article/parts/description";
import FullFeatures from "../components/article/parts/full-features";
import Images from "../components/article/parts/images";
import Costs from "../components/article/parts/costs";
import Agency from "../components/article/parts/agency";
import OptionsSelection, { OptionsSelectionData } from "../components/article/options-selection";
import Modal, { InnerModal, ModalHandle } from "../components/common/modal";
import Reviews from "../components/article/parts/reviews";
import AllReviews from "../components/article/parts/all-reviews";
import Comment from "../components/cards/comment";
import BookingArticlePreview from "../components/article/booking/article-preview";
import BookingBox from "../components/article/booking-box";
import "./open-article.scss";
import { FormattedBookingOption, isBookingPrice, toGQLBookingPrice } from "@ct-react/calendar";

const transDefs = defineMessages({
  reviewTitle: { id: "booking-comments-title", defaultMessage: "Commentaires clients" },
  reviewLink: { id: "booking-reviews-modal-link", defaultMessage: "Voir tous les commentaires" }
});

const ALL_ARTICLE_GQL_DATA = gql`
  ${ALL_FEATURES_FRAGMENT}
  ${COST_DESCRIPTIONS_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  ${MERCHANT_CONTACT_FRAGMENT("MerchantContact")}
  query GetArticle($articleId: ID!, $commentsLength: Int!) {
    detail: articleDetail(articleId: $articleId) {
      ... on RentalAccommodationArticle {
        id
        slug
        title { value }
        description { matchLang value }
        images { main aspectRatio assets { url breakpoint } }
        virtualVisits { url }
        address { resume }
        coordinates
        classification
        lowestPrice { onDemand type value }
        features { ...AllFeaturesFields }
        costDescriptions { ...AllCostDescriptionsFields }
        merchant { slug }
        seo { title { value } description { value } image keywords translatableKeywords { key value } }
      }
    }
    agency: articleRelatedContact(articleId: $articleId) { ...MerchantContactFields }
    reviews: bookingAccommodationReviews(articleId: $articleId, length: $commentsLength) {
      averageRate
      rates { welcome reservation overallComfort bedsComfort cleanliness accuracy location satisfaction value }
      ratesCount
      commentsCount
      comments { reviewedAt customerName averageRate comment withAnswer }
    }
  }
`;

const CHECK_OPTIONS_GQL_DATA = gql`
  query CheckOptions($item: AnyOptionFilterInput!) {
    options: cartCheckOptionsBeforeAddItem(item: $item, type: BENEFIT) {
      ... on BenefitCartOption {
        optionId title { value } description { value } images forceSelection
        criteria { quantity }
        grid { quantity price }
      }
    }
  }
`;

const ADD_TO_CART_GQL_MUT = gql`
  mutation AddToCart($sessionId: String, $userId: String, $item: AnyAddCartItemInput!, $options: [AnyAddCartItemOptionInput!]) {
    cartAddItem(sessionId: $sessionId, userId: $userId, item: $item, options: $options) {
      cartId
      fullPrice { amount discount downPayment }
    }
  }
`;

const OpenArticle = () => {

  const intl = useIntl();
  const isMounted = useIsMounted();
  const navigate = useLocaleNavigate();
  const { sessionId, userId } = useVisitor();
  const { options: { customView } } = useAppConfig();
  const commentsLength = 5;

  // handle entry query definition

  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ queryParams, setQueryParams ] = useState<AnyPeriod | undefined>(parseOpenArticleQueryParams(searchParams));

  // component state

  const reviewsRef = useRef<HTMLDivElement>(null);
  const modalReviewsRef = useRef<ModalHandle>(null);
  const agencyRef = useRef<HTMLDivElement>(null);

  const [ failure, setFailure ] = useState<boolean>(!parseRefIdQueryParam(searchParams));
  const [ articleId, setArticleId ] = useState<string | undefined>(parseRefIdQueryParam(searchParams));
  const [ loading, setLoading ] = useState<boolean>(true);
  const [ content, setContent ] = useState<any | null>(null);
  const [ bookingSelection, setBookingSelection ] = useState<FormattedBookingOption | undefined>(undefined);

  const [ actionLoading, setActionLoading ] = useState<boolean>(false);
  const [ optionsSelectionData, setOptionsSelectionData ] = useState<OptionsSelectionData | undefined>(undefined);

  const [ openReviewModal, setOpenReviewModal ] = useState<boolean>(false);

  // component effects
  // region

  // handle all display on article id change

  useEffect(() => {
    if (!isMounted) return;
    const refId = parseRefIdQueryParam(searchParams);
    !!refId ? setArticleId(refId) : setFailure(true);
  }, [ searchParams ]);

  // active loading view on article id change

  useEffect(() => {
    if (!isMounted) return;
    setLoading(true);
  }, [ articleId ]);

  // endregion

  // data loading & mutation
  // region

  useQuery(ALL_ARTICLE_GQL_DATA, {
    variables: { articleId, commentsLength },
    skip: !articleId,
    ssr: false,
    onError: () => setFailure(true),
    onCompleted: ({
      detail,
      agency,
      reviews
    }) => {
      const { description, features, costDescriptions: descriptions, seo, ...summary } = detail;
      const { __typename, ...costDescriptions } = descriptions;
      setContent({ seo, summary, description, features, costDescriptions, reviews, agency });
      setLoading(false);
    }
  });

  const [ checkCartOptions ] = useLazyQuery(CHECK_OPTIONS_GQL_DATA, { fetchPolicy: "network-only" });

  const [ addToCart ] = useMutation(ADD_TO_CART_GQL_MUT, {
    onCompleted: () => navigate("/cart"),
    refetchQueries: [ "CartBadge" ],
    awaitRefetchQueries: true
  });

  // endregion

  // dom and component events interactions
  // region

  const onRateClick = () => reviewsRef?.current?.scrollIntoView({ behavior: "smooth", block: "center" });

  const onBookingChoiceChange = (choice?: FormattedBookingOption) => {
    setBookingSelection(choice);
    rebuildOpenArticleQueryParams(searchParams, choice?.period);
    setSearchParams(searchParams, { replace: true });
    setQueryParams(choice?.period || undefined);
  }

  const onAction = (choice?: FormattedBookingOption) => {
    if (!choice) return;
    if (!choice.bookable || !isBookingPrice(choice.price)) {
      agencyRef?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
      return;
    }

    setActionLoading(true);

    const currency = choice.price.amount.currency;
    const cartItem = {
      articleId: articleId!,
      period: toGQLPeriod(choice.period),
      price: toGQLBookingPrice(choice.price.detailed)
    };
    const { price, ...item } = cartItem;

    checkCartOptions({ variables: { item: { rentalAccommodation: item } } })
      .then(({ data: { options: optionDefs } }) => {
        if (optionDefs.length > 0) {
          setOptionsSelectionData({ currency, cartItem, optionDefs });
          return new Promise((res) => res(void 0));
        }
        return addToCart({
          variables: {
            ...(!userId) && { sessionId },
            ...(!!userId) && { userId },
            item: { rentalAccommodation : cartItem }
          }
        });
      })
      .finally(() => setActionLoading(false));
  }

  const onOptionsSelected = (item: AddCartItem, options: AddCartItemOption[]) => {
    addToCart({
      variables: {
        ...(!userId) && { sessionId },
        ...(!!userId) && { userId },
        item: { rentalAccommodation: item },
        options: options.map(benefit => ({ benefit }))
      }
    }).then(() => setOptionsSelectionData(undefined))
  }

  const onReviewMoreClick = (index: number) => {
    setOpenReviewModal(true);
    modalReviewsRef.current?.scrollToAnchor(`comment-${index}`);
  }

  // endregion

  // rendering
  // region

  const withPermanentBookingBox = useDisplayUntilTablet();
  const contentColClasses = classNames("rla-content", { "rla-two-col": !withPermanentBookingBox });
  const bookingClasses = classNames("rla-sticky-booking", { hidden: loading, mobile: withPermanentBookingBox });
  const mapBoxClasses = classNames("map-box", "back", { imaged: !loading && customView === CustomViewType.HOTEL && !!content.agency.id });

  if (failure)
    return <NotFound />;

  return (
    <main className="rl-article">

      {!!content?.seo && <SeoHelmet {...{
        ...content.seo,
        canonical: buildArticleUrl(content.summary)
      }} />}

      <OptionsSelection data={optionsSelectionData} onSelection={onOptionsSelected} />

      <Wrapper.Centered as="section" className="rla-map">
        {loading
          ? <Skeleton className="map-box" inline={true} />
          : (
            <div className={mapBoxClasses}
                 {...(customView === CustomViewType.HOTEL) && { style: {
                   backgroundImage : `url(/assets/custom-view/hotel-${content.agency.id}.webp)`
                 } }}>
              {customView !== CustomViewType.HOTEL &&
                <MapContainer zoom={16} center={content.summary.coordinates} layersControlPosition="bottomleft">
                  <MapMarker position={content.summary.coordinates} />
                </MapContainer>
              }
            </div>)
        }
      </Wrapper.Centered>

      <Wrapper.Centered className={contentColClasses}>

        <article className="rla-data">
          <Title {...{
            loading,
            className: "rla-separator",
            ...(!loading) && {
              data: {
                title: content.summary.title.value,
                address: content.summary.address.resume,
                thumbnail: content.summary.images.find((i: any) => i.main),
                ...(!!content.reviews?.averageRate) && { rate: content.reviews.averageRate },
                lowestPrice: content.summary.lowestPrice,
                bookingSelection
              },
              onRateClick
            }
          }} />
          <Description {...{
            loading,
            className: "rla-separator blank",
            ...(!loading) && {
              data: {
                classification: content.summary.classification,
                description: content.description
              }
            }
          }} />
          <Images {...{
            loading,
            className: "rla-separator",
            ...(!loading) && {
              data: {
                images: content.summary.images,
                ...(!!content.summary.virtualVisits) && { virtualVisits: content.summary.virtualVisits }
              }
            }
          }} />
          <FullFeatures {...{
            loading,
            className: "rla-separator",
            ...(!loading) && { data: content.features }
          }} />
          <Costs {...{
            loading,
            className: "rla-separator",
            ...(!loading) && { data: content.costDescriptions }
          }} />
        </article>

        <aside className={classNames(bookingClasses)}>
          {(!loading && !!articleId) &&
            <div className="rla-booking-wrapper">
              {!withPermanentBookingBox && <BookingArticlePreview loading={false} data={content.summary} />}
              <BookingBox articleId={articleId}
                          mobileView={withPermanentBookingBox}
                          processing={actionLoading}
                          presetPeriod={queryParams}
                          onBookingChoiceChange={onBookingChoiceChange}
                          onAction={onAction} />
            </div>
          }
        </aside>
      </Wrapper.Centered>

      <div className="rla-complements">

        {(!loading && !!content.reviews) &&
          <>
            <Wrapper.Centered className="rla-reviews-wrapper">
              <div className="rla-separator">
                <Reviews ref={reviewsRef}
                  {...{
                    loading: false,
                    data: content.reviews
                  }} />
              </div>
            </Wrapper.Centered>
            {content.reviews.comments.length > 0 &&
              <Wrapper.Slideable data-nosnippet={true}
                                 className="rla-comments-wrapper"
                                 wrapperClassName="rla-comments grid"
                                 title={intl.formatMessage(transDefs.reviewTitle)}>
                {content.reviews.comments.map((comment: CommentModel<string>, i: number) =>
                  <Comment key={i}
                           data={comment}
                           moreable={() => onReviewMoreClick(i)} />
                )}
                <Modal ref={modalReviewsRef}
                       size="lg"
                       open={openReviewModal}
                       onOpenChange={setOpenReviewModal}>
                  <InnerModal.Trigger as="div" className="rw-card show-all">
                    <span className="more">
                      {intl.formatMessage(transDefs.reviewLink)}
                    </span>
                  </InnerModal.Trigger>
                  <InnerModal.Content title={intl.formatMessage(transDefs.reviewTitle)}>
                    <AllReviews articleId={articleId!} />
                  </InnerModal.Content>
                </Modal>
              </Wrapper.Slideable>
            }
          </>
        }

        {(!loading && !!content.summary && customView !== CustomViewType.HOTEL) &&
          <Wrapper.Centered>
            <div className="rla-separator">
              <Agency ref={agencyRef}
                      loading={false}
                      data={{
                        agency: content.agency,
                        articleId,
                        ...(!!bookingSelection) && { bookingChoice: bookingSelection.period }
                      }} />
            </div>
          </Wrapper.Centered>
        }

      </div>
    </main>);

  // endregion

}

export default OpenArticle;
