import React, { useEffect, useMemo, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useLocation, useOutletContext } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import { IconChevronsRight, IconLoader3, IconSquareRoundedXFilled } from "@tabler/icons-react";
import { useAppConfig } from "@ct-react/core";
import { useLocaleNavigate, useLocaleUrl } from "@ct-react/locale";
import { FULL_CART_GQL_DATA } from "@shared/gql/queries";
import { cartTranslations } from "../../i18n/sharable-defs";
import { useCartBadge, useLazyCartBadge } from "../../hooks/cart-step-check";
import { CartContext } from "./cart-outlet";
import { Button } from "../../components/common/minimals";
import Modal, { InnerModal } from "../../components/common/modal";
import PaymentTypeChoiceForm, { PaymentChoiceValue } from "../../components/forms/payment-type-choice";
import { CartPrice } from "../../components/cart/cart-price";
import "./common.scss";
import "./payment.scss";

const transDefs = defineMessages({
  expiredTitle: { id: "cart-process-expired-title", defaultMessage: "Votre panier est vide." },
  expiredMsg: { id: "cart-process-expired-msg", defaultMessage: "Le blocage de l'hébergement vous permettant de procéder à la réservation a expiré. Veuillez recommencer si vous souhaitez encore réserver." }
})
const CART_CHECKOUT_GQL_MUT = gql`
  mutation CartCheckout($sessionId: String, $userId: String, $checkout: CheckoutCartInput!) {
    cartCheckout(sessionId: $sessionId, userId: $userId, checkout: $checkout) { paymentUrl }
  }
`;

type ExpiredCartProps = {
  open: boolean;
  onConfirmation: () => void;
}

const ExpiredCart = (
  {
    open: openProps,
    onConfirmation
  }: ExpiredCartProps) => {

  const intl = useIntl();
  const [ open, setOpen ] = useState<boolean>(false);

  useEffect(() => {
    openProps && setOpen(true);
  }, [ openProps ]);

  const onClosing = (state: boolean) => {
    setOpen(state);
    !state && onConfirmation();
  }

  const buttonRendering = useMemo(() => (
    <Button type="button"
            className="link bolder small inline-icon"
            onClick={() => onClosing(false)}>
      {intl.formatMessage(cartTranslations.backHome)}
      <IconChevronsRight />
    </Button>
  ), []);

  return (
    <Modal size="sm"
           closableCross={false}
           open={open}
           onOpenChange={onClosing}>
      <InnerModal.Content className="expired-cart-modal"
                          footer={buttonRendering}>
        <div className="expired-message">
          <IconSquareRoundedXFilled/>
          <span>{intl.formatMessage(transDefs.expiredTitle)}</span>
        </div>
        <p>{intl.formatMessage(transDefs.expiredMsg)}</p>
      </InnerModal.Content>
    </Modal>);
}

const CartPayment = () => {

  const intl = useIntl();
  const { baseUrl } = useAppConfig();
  const navigate = useLocaleNavigate();
  const { state } = useLocation();
  const { buildLocaleHref } = useLocaleUrl();

  const itemsCount = useCartBadge();
  const { sessionId, userId, changeIndex } = useOutletContext<CartContext>();

  const [ paymentType, setPaymentType ] = useState<PaymentChoiceValue>("full");
  const [ checking, setChecking ] = useState<boolean>(false);
  const [ expiredCart, setExpiredCart ] = useState<boolean>(false);

  useEffect(() => {
    itemsCount === 0 && navigate("/cart");
    !state?.chosenVisitorType && navigate("/cart/account", { state });
    !state?.visitorInfo && navigate("/cart/information", { state });
    changeIndex(3);
  }, []);

  // data

  const { data, loading } = useQuery(FULL_CART_GQL_DATA, {
    variables: { sessionId, userId },
    fetchPolicy: "cache-and-network"
  });

  const checkItemsCount = useLazyCartBadge();

  const [ cartCheckout ] = useMutation(CART_CHECKOUT_GQL_MUT, {
    variables: {
      sessionId,
      userId,
      checkout: {
        customer: state?.visitorInfo || {},
        hasFullPayment: paymentType === "full",
        redirect: {
          baseUrl,
          successPath: buildLocaleHref("/cart/payment/success"),
          cancelPath: buildLocaleHref("/cart/payment/cancellation")
        }
      }
    }
  });

  // dom interactions

  const onPay = async () => {
    setChecking(true);
    checkItemsCount()
      .catch(() => {
        setExpiredCart(true);
        setChecking(false);
      })
      .then(() => cartCheckout())
      .then(({ data: { cartCheckout } }) => !cartCheckout.paymentUrl
        ? navigate("/cart/payment/success")
        : window.location.href = cartCheckout.paymentUrl);
  }

  // rendering

  if (loading || !data || !data?.cart)
    return (<Skeleton className="cart-skeleton" />);

  return (
    <>
      <ExpiredCart open={expiredCart}
                   onConfirmation={() => navigate("/")} />
      <div className="cart-item-wrapper">

        <div className="rl-two-col">
          <div>
            <h2>
              {intl.formatMessage({
                id: "cart-process-payment-model-title",
                defaultMessage: "Modèle de paiement"
              })}
            </h2>
            <PaymentTypeChoiceForm cartPrice={data.cart.fullPrice}
                                   value={paymentType}
                                   onChange={setPaymentType} />
          </div>
          <CartPrice data={data.cart.fullPrice} />
        </div>

        <div className="rl-two-col stepper-actions">
          <button type="button"
                  className="back"
                  onClick={() => navigate("/cart/information", {state})}
                  disabled={checking}>
            {intl.formatMessage({
              id: "cart-process-back-information-button",
              defaultMessage: "Modifier vos informations"
            })}
          </button>
          <button type="button"
                  disabled={checking || !paymentType}
                  onClick={onPay}>
            {checking
              ? <IconLoader3 className="loading-action-tabler"/>
              : intl.formatMessage({
                id: "cart-process-next-pay-button",
                defaultMessage: "Payer et finaliser la réservation"
              })
            }
          </button>
        </div>

      </div>
    </>);

}

export default CartPayment;
