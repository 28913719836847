import React, { useMemo, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useIntl } from "react-intl";
import { useLocation, useOutletContext } from "react-router-dom";
import { IconChevronsRight } from "@tabler/icons-react";
import { LocaleLink, useLocaleContext, useLocaleNavigate } from "@ct-react/locale";
import { bookingTranslations } from "../../i18n/sharable-defs";
import { FULL_CART_GQL_DATA } from "@shared/gql/queries";
import { CartItem as CartItemModel } from "../../models/cart";
import { CartContext } from "./cart-outlet";
import CartItem from "../../components/cart/cart-item";
import { CartPrice } from "../../components/cart/cart-price";
import LastMinuteWarning from "../../components/cart/last-minute-warning";
import "./common.scss";
import "./cart.scss";

const REMOVE_CART_ITEM_GQL_MUT = gql`
  mutation RemoveFromCart($sessionId: String, $userId: String, $itemId: UUID!) {
    cartRemoveItem(sessionId: $sessionId, userId: $userId, item: $itemId) { cartId }
  }
`;

const Cart = () => {

  const intl = useIntl();
  const { locale } = useLocaleContext();
  const navigate = useLocaleNavigate();
  const { state } = useLocation();

  const { sessionId, userId, changeIndex } = useOutletContext<CartContext>();
  const [ removingItem, setRemovingItem ] = useState<string | undefined>(undefined);

  // data loading and mutation

  const { data } = useQuery(FULL_CART_GQL_DATA, {
    variables: { sessionId, userId },
    fetchPolicy: "cache-and-network",
    onCompleted: () => changeIndex(1)
  });

  const [ removeFromCart ] = useMutation(REMOVE_CART_ITEM_GQL_MUT, {
    refetchQueries: [ "CartBadge", "UserCart" ],
    awaitRefetchQueries: true
  });

  // dom interaction

  const onRemoveItem = (itemId: string) => {
    const variables = { sessionId, userId, itemId };
    setRemovingItem(itemId);
    removeFromCart({ variables }).then(() => setRemovingItem(undefined));
  }

  const nextButtonRendering = useMemo(() =>
    <button type="button" onClick={() => navigate("/cart/information", { state })}>
      {intl.formatMessage(bookingTranslations.book)}
    </button>, [ locale ]);

  // rendering

  if (!data || !data?.cart || data.cart.items.length === 0)
    return (
      <div className="cart-item-wrapper empty-cart">
        <div className="title">
          {intl.formatMessage({
            id: "cart-empty-title",
            defaultMessage: "Vous n'avez encore rien trouvé ?"
          })}
        </div>
        <div className="redirect">
          {intl.formatMessage({
            id: "cart-empty-redirect",
            defaultMessage: "Parcourez tous les hébergements disponibles en suivant ce lien."
          })}
          <LocaleLink to="/resorts/any/search">
            <IconChevronsRight />
          </LocaleLink>
        </div>
      </div>);

  return (
    <section className="cart-content">
      {data.cart.items.map((item: CartItemModel, i: number) =>
        <CartItem key={i}
                  className="cart-item-wrapper"
                  {...{
                    ...item,
                    ...(data.cart.items.length === 1) && { nextStep:
                        <div className="stepper-actions">
                          {nextButtonRendering}
                        </div>
                    },
                    actionRemoving: !!removingItem && removingItem === item.itemId
                  }}
                  onRemove={onRemoveItem} />
      )}
      {data.cart.items.length > 1 &&
        <div className="cart-full-price rl-two-col stepper-actions">
          <div />
          <div className="cart-item-wrapper margin">
            <CartPrice data={data.cart.fullPrice} />
            <LastMinuteWarning price={data.cart.fullPrice} />
            {nextButtonRendering}
          </div>
        </div>
      }
    </section>);

}

export default Cart;
