import React, { useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import { IconInfoSquareRoundedFilled } from "@tabler/icons-react";
import { castPrice } from "@ct-react/core";
import { useLocaleFormatter } from "@ct-react/locale";
import { bookingTranslations } from "../../i18n/sharable-defs";
import { DataProps } from "../../tools/components";
import { CartItemPrice as CartItemPriceModel, CartPrice as CartPriceModel } from "../../models/cart";
import Tooltip, { InnerTooltip } from "../common/tooltip";
import "./cart-price.scss";

const transDefs = defineMessages({
  title: { id: "cart-item-price-title", defaultMessage: "Détails du prix" },
  unitLabel: { id: "cart-item-price-unit", defaultMessage: "Prix unitaire" },
  discountLabel: { id: "cart-item-price-discount", defaultMessage: "Réduction" },
  amountLabel: { id: "cart-item-price-amount", defaultMessage: "Montant" },
  downPaymentLabel: { id: "cart-item-price-down", defaultMessage: "Acompte immédiat" }
});

const CartItemPrice = ({ data }: DataProps<CartItemPriceModel>) => {

  const intl = useIntl();
  const { print } = useLocaleFormatter();

  const barePrice = useMemo(() => castPrice(data.bare), [ data.bare ]);
  const discount = useMemo(() => !!data.discount ? castPrice(data.discount) : null, [ data.discount ]);

  const benefits = useMemo(() => {
    const options = data.options.filter((opt: any) => opt.type !== "CUSTOMER_CHARGE").map((opt: any) => castPrice(opt.amount));
    return options.length === 0 ? null :
      { amount: options.reduce((acc, cur) => acc + cur.amount, 0), currency: options[0].currency };
  }, [ data ]);

  const customerCharge = useMemo(() => {
    const options = data.options.filter((opt: any) => opt.type === "CUSTOMER_CHARGE").map((opt: any) => castPrice(opt.amount));
    return options.length === 0 ? null :
      { amount: options.reduce((acc, cur) => acc + cur.amount, 0), currency: options[0].currency };
  }, [ data ]);

  const fullAmount = useMemo(() => castPrice(data.amount), [ data.amount ]);
  const downPayment = useMemo(() => castPrice(data.downPayment), [ data.downPayment ]);

  return <div className="cart-price-detail">
    <h2>{intl.formatMessage(transDefs.title)}</h2>
    <dl className="price-list">
      <dt>{intl.formatMessage(transDefs.unitLabel)}</dt>
      <dd>{print.price(barePrice)}</dd>
      {!!discount &&
          <>
            <dt>{intl.formatMessage(transDefs.discountLabel)}</dt>
            <dd className="discount">{print.price(discount)}</dd>
          </>
      }
      {(!!benefits) &&
        <>
          <dt>{intl.formatMessage(bookingTranslations.benefits)}</dt>
          <dd>{print.price(benefits)}</dd>
        </>
      }
      {(!!customerCharge) &&
        <>
          <dt className="charges">
            <Tooltip placement="bottom">
              <InnerTooltip.Trigger as="span" className="tooltip-more">
                {intl.formatMessage(bookingTranslations.customerCharge)}
                <IconInfoSquareRoundedFilled />
              </InnerTooltip.Trigger>
              <InnerTooltip.Content>{intl.formatMessage(bookingTranslations.customerChargeTooltip)}</InnerTooltip.Content>
            </Tooltip>
          </dt>
          <dd>{print.price(customerCharge)}</dd>
        </>
      }
    </dl>
    <dl className="price-list total">
      <dt>{intl.formatMessage(transDefs.amountLabel)}</dt>
      <dd>{print.price(fullAmount)}</dd>
      {(downPayment.amount > 0 && downPayment.amount != fullAmount.amount) &&
        <>
          <dt>{intl.formatMessage(transDefs.downPaymentLabel)}</dt>
          <dd>{print.price(downPayment)}</dd>
        </>
      }
    </dl>
  </div>;

}

export const CartPrice = ({data}: DataProps<CartPriceModel>) => {

  const intl = useIntl();
  const { print } = useLocaleFormatter();

  const fullAmount = useMemo(() => castPrice(data.amount), [ data.amount ]);
  const downPayment = useMemo(() => castPrice(data.downPayment), [ data.downPayment ]);

  return (
    <div className="cart-price-detail">
      <h2>Total</h2>
      <dl className="price-list" />
      <dl className="price-list total">
        <dt>{intl.formatMessage(transDefs.amountLabel)}</dt>
        <dd>{print.price(data.amount)}</dd>
        {(downPayment.amount > 0 && downPayment.amount != fullAmount.amount) &&
          <>
            <dt>{intl.formatMessage(transDefs.downPaymentLabel)}</dt>
            <dd>{print.price(downPayment)}</dd>
          </>
        }
      </dl>
    </div>);

};

export default CartItemPrice;
