import React, { forwardRef, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { castPrice, Price, useIsMounted } from "@ct-react/core";
import { useLocaleFormatter } from "@ct-react/locale";
import { CartPrice } from "../../models/cart";
import "./payment-type-choice.scss";

export type PaymentChoiceValue = "full" | "partial";

type PaymentTypeChoiceFormProps = {
  cartPrice: CartPrice;
  value: PaymentChoiceValue;
  onChange: (val: PaymentChoiceValue) => void;
};

const PaymentTypeChoiceForm = forwardRef<HTMLFormElement, PaymentTypeChoiceFormProps>((
  {
    cartPrice : price,
    value: defaultValue,
    onChange
  }: PaymentTypeChoiceFormProps, forwardedRef) => {

  const isMounted = useIsMounted();
  const intl = useIntl();
  const { print } = useLocaleFormatter();

  const diff = useMemo((): Price => {
    const amount = castPrice(price.amount);
    const downPayment = castPrice(price.downPayment);
    return { amount: amount.amount - downPayment.amount, currency: amount.currency };
  }, [ price ]);

  const [ activePartial, setActivePartial ] = useState<boolean>(diff.amount > 0);
  const [ value, setValue ] = useState<PaymentChoiceValue>(defaultValue);

  useEffect(() => {
    const newVal = diff.amount > 0;
    if (!isMounted || newVal === activePartial)
      return;
    setActivePartial(newVal);
  }, [ price ]);

  useEffect(() => {
    if (!isMounted) return;
    onChange(value)
  }, [ value ]);

  return (
    <form ref={forwardedRef}
          className="payment-choice">
      <label className="grouped-radio">
        <input type="radio"
               name="choice"
               id="full"
               value="full"
               checked={value === "full"}
               onChange={e => setValue(e.target.value as PaymentChoiceValue)} />
        <span className="full-radio">
          <span className="label">
            {intl.formatMessage({
              id: "cart-process-payment-choice-full-label",
              defaultMessage: "Payer le montant total"
            })}
          </span>
          <label htmlFor="full">{print.price(price.amount)}</label>
          <span className="checkmark"/>
          <span className="detail">
            {intl.formatMessage({
              id: "cart-process-payment-choice-full-detail",
              defaultMessage: "Payez le total maintenant et vous aurez terminé."
            })}
          </span>
        </span>
      </label>
      <label className="grouped-radio">
        <input type="radio"
               name="choice"
               id="partial"
               value="partial"
               checked={value === "partial"}
               onChange={e => setValue(e.target.value as PaymentChoiceValue)}
               disabled={!activePartial} />
        <span className="full-radio">
          <span className="label">
            {intl.formatMessage({
              id: "cart-process-payment-choice-partial-label",
              defaultMessage: "Payer une partie maintenant et l'autre plus tard"
            })}
          </span>
          {activePartial &&
            <label htmlFor="partial">{print.price(price.downPayment)}</label>
          }
          <span className="checkmark"/>
          {activePartial &&
            <span className="detail">
              <span className="block">
                {intl.formatMessage({
                  id: "cart-process-payment-choice-partial-detail",
                  defaultMessage: "Payez {down} maintenant et le solde ({diff}) vous sera prélevé ultérieurement."
                }, {
                  down: print.price(price.downPayment),
                  diff: print.price(diff)
                })}
              </span>
              <span className="block">
                {intl.formatMessage({
                  id: "cart-process-payment-choice-partial-detail-cost",
                  defaultMessage: "Aucun frais supplémentaires."
                })}
              </span>
            </span>
          }
          {!activePartial &&
            <span className="detail">
              <span className="block">
                {intl.formatMessage({
                  id: "cart-process-payment-choice-partial-disabled",
                  defaultMessage: "Cette option n'est pas disponible pour cette réservation."
                })}
              </span>
            </span>
          }
        </span>
      </label>
    </form>);

});

export default PaymentTypeChoiceForm;
