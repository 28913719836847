import fetch from "isomorphic-fetch";
import { ApolloClient, ApolloLink, createHttpLink, from, InMemoryCache } from "@apollo/client";
import { ApolloClientOptions } from "@apollo/client/core/ApolloClient";
import { onError } from "@apollo/client/link/error";
import { AppConfig } from "@ct-react/core";
import { Locale } from "@ct-react/locale";

const buildConsumerMiddleware = (channelId: string, locale?: Locale) => {
  return new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        "GQL-Consumer-Channel": channelId,
        ...(!!locale) && { "GQL-Consumer-Lang": locale.basename }
      }
    }));
    return forward(operation);
  });
}

const buildApolloClient = (context: AppConfig, ssrMode: boolean = false, locale?: Locale, restoringCache?: any) => {
  const cache = new InMemoryCache({
    possibleTypes: {
      AnyArticle: [ "RentalAccommodationArticle", "SeasonalAccommodationArticle", "AnnualAccommodationArticle", "SaleAccommodationArticle" ]
    },
    typePolicies: {
      AnyArticle: {
        fields: {
          features: {
            merge: (existing, incoming) => ({ ...existing, ...incoming })
          }
        }
      }
    }
  });
  if (!!restoringCache) cache.restore(JSON.parse(restoringCache));

  return new ApolloClient({
    ssrMode,
    connectToDevTools: !ssrMode && context.env === "development",
    link: from([
      onError(e => console.error("gql error", e)),
      buildConsumerMiddleware(context.gqlChannelId, locale),
      createHttpLink({ uri: context.gqlClientUrl, fetch })
    ]),
    cache
  } as unknown as ApolloClientOptions<InMemoryCache>);
}

export default buildApolloClient;
