import React, { useCallback, useEffect, useRef, useState } from "react";
import { Moment } from "moment";
import { defineMessages, useIntl } from "react-intl";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { IconEdit, IconLoader3, IconSquareRoundedXFilled, IconX } from "@tabler/icons-react";
import Skeleton from "react-loading-skeleton";
import { autoUpdate, offset, useClick, useDismiss, useFloating, useInteractions } from "@floating-ui/react";
import {
  AnyPeriod,
  castPeriod,
  classNames,
  isValidPeriod,
  Period,
  toGQLPeriod,
  useAppConfig,
  useIsMounted
} from "@ct-react/core";
import {
  castBookingConfig,
  castBookingOption,
  FormattedBookingOption,
  RangePickerController,
  useDefaultCalendarBlock
} from "@ct-react/calendar";
import { useLocaleFormatter } from "@ct-react/locale";
import { BOOKING_SUGGESTION_FRAGMENT } from "@shared/gql/fragments";
import { agencyTranslations, bookingTranslations } from "../../i18n/sharable-defs";
import { useDisplayDesktop } from "../../tools/breakpoints";
import { Button } from "../common/minimals";
import PeriodInputResume from "./booking/period-input-resume";
import BookPriceResume from "./booking/book-price-resume";
import "./booking-box.scss";
import { usePickerLabelize } from "../../hooks/generic";

type ConflictableBookingChoice = (FormattedBookingOption & { conflicted?: boolean }) | undefined;
enum DisplayState { Empty, Processing, OnEdit, Conflicted, Bookable, Contactable }

const ALL_OPTIONS_GQL_DATA = gql`
  query GetBookingOptions($articleId: ID!) {
    config: bookingAccommodationConfig(articleId: $articleId) {
      period starters { startingAt dayOffset enders { endingAt bookable } } blocks
    }
  }
`;

const LAZY_PRICE_GQL_DATA = gql`
  ${BOOKING_SUGGESTION_FRAGMENT}
  query GetBookingPrice($articleId: ID!, $period: DatePeriod!) {
    selectionPrice: bookingAccommodationPriceDetail(articleId: $articleId, period: $period) {
      ...BookingSuggestionFields
    }
  }
`;

const transDefs = defineMessages({
  clean: { id: "booking-picker-clear-choice-button", defaultMessage: "Effacer les dates" },
  cancel: { id: "booking-picker-cancel-button", defaultMessage: "Fermer" },
  close: { id: "booking-picker-close-button", defaultMessage: "Valider" },
  onDemand: { id: "booking-picker-ondemand-textual", defaultMessage: "Disponible sur demande uniquement. Faites suite en contactant l'agence directement." },
  conflict: { id: "booking-picker-conflict", defaultMessage: "Cette période est nouvellement devenue indisponible." }
});

type BookingBoxProps = {
  articleId: string;
  mobileView: boolean;
  processing?: boolean;
  presetPeriod?: AnyPeriod;
  onBookingChoiceChange?: (bookingChoice?: FormattedBookingOption) => void;
  onAction?: (bookingChoice: FormattedBookingOption) => void;
}

const BookingBox = (
  {
    articleId,
    mobileView,
    processing = true,
    presetPeriod: initedBookingChoice,
    onBookingChoiceChange = () => void 0,
    onAction: onCallbackAction = () => void 0
  }: BookingBoxProps) => {

  const intl = useIntl();
  const isMounted = useIsMounted();
  const { options: { blockCart } } = useAppConfig();
  const { print } = useLocaleFormatter();
  const calendarLabelize = usePickerLabelize();

  // inited state

  const initedPickerRange = !!initedBookingChoice ? castPeriod(initedBookingChoice) : undefined;
  const initedPickerFocus = !!initedBookingChoice ? undefined : "start";

  // component states

  const [ displayState, setDisplayState ] = useState<DisplayState>(DisplayState.Processing);
  const [ bookingSelection, setBookingSelection ] = useState<ConflictableBookingChoice>(undefined);
  const {
    config: [ bookingConfig, setBookingConfig ],
    range: [ pickerRange, setPickerRange ],
    focus: [ pickerFocus, setPickerFocus ],
    isDayBlocked
  } = useDefaultCalendarBlock(initedPickerRange, initedPickerFocus);

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  // mount / unmount

  useEffect(() => {
    !!initedBookingChoice && fetchPrice(initedBookingChoice).then();
    return () => stopPollingBooking();
  }, []);

  // data loading

  const { stopPolling : stopPollingBooking, loading: loadingOptions  } = useQuery(ALL_OPTIONS_GQL_DATA, {
    variables: { articleId },
    ssr: false,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    pollInterval: 30 * 1000,
    onCompleted: ({ config }) => {
      const configs = castBookingConfig(config);
      setBookingConfig(configs);
      if (!bookingSelection) return;

      const withConflict = configs.blocks.some(d => d.isBetween(bookingSelection.period.start, bookingSelection.period.end, "day", "[)"));
      if (withConflict) {
        setPickerRange({});
        setBookingSelection(prev => ({ ...prev!, conflicted: true }));
      } else if(!!bookingSelection.conflicted) {
        const { conflicted, ...next } = bookingSelection;
        setPickerRange(bookingSelection.period);
        setBookingSelection(next);
      }
    }
  });

  const [ getPrice, { loading: priceLoading } ] = useLazyQuery(LAZY_PRICE_GQL_DATA, {
    ssr: false,
    fetchPolicy: "no-cache",
    onCompleted: ({ selectionPrice }) => {
      const result = castBookingOption(selectionPrice);
      setBookingSelection(prev => !!result ? ({ ...result, ...(!!prev?.conflicted) && { conflicted: prev.conflicted } }) : result);
      onBookingChoiceChange!(result);
    }
  });

  // component logics

  // lazy request fetching price

  const fetchPrice = useCallback(async (period: AnyPeriod) => await getPrice({
    variables: {
      articleId,
      period: toGQLPeriod(period)
    }
  }), [ pickerRange ]);

  // react on period selection

  useEffect(() => {
    if (!isMounted) return;
    if (!bookingSelection) setBookingSelection(undefined);
    if (pickerFocus === "end") return;
    if (isValidPeriod(pickerRange)) fetchPrice(pickerRange as Period<Moment>).then();
    else onBookingChoiceChange(undefined);
  }, [ pickerFocus ]);

  // picker floating display

  const [ pickerOpen, setPickerOpen ] = useState<boolean>(false);

  const { x, y, reference, floating, strategy, context } = useFloating({
    open: pickerOpen,
    onOpenChange: setPickerOpen,
    strategy: mobileView ? "fixed" : "absolute",
    placement: mobileView ? "top-end" : "bottom-end",
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(({ placement, rects }) =>  placement.match(/^top/) ? 0 : -1 * rects.reference.height)
    ]
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context, { toggle: true }),
    useDismiss(context, { outsidePress: !mobileView })
  ]);

  // state effects

  useEffect(() => {
    if (processing || loadingOptions) {
      setDisplayState(DisplayState.Processing);
      return;
    }
    if (!!bookingSelection) {
      if (!!bookingSelection.conflicted) setDisplayState(DisplayState.Conflicted);
      else if (!bookingSelection.bookable) setDisplayState(DisplayState.Contactable);
      else setDisplayState(DisplayState.Bookable);
      return;
    }
    setDisplayState(pickerOpen ? DisplayState.OnEdit : DisplayState.Empty);
  }, [ processing, bookingSelection, loadingOptions, pickerOpen ]);


  // dom interactions

  const onReset = () => {
    setPickerFocus("start");
    setPickerRange({});
    setBookingSelection(undefined);
  }

  const onAction = () => {
    if ([ DisplayState.Bookable, DisplayState.Contactable ].includes(displayState) && !!bookingSelection) {
      if (displayState === DisplayState.Contactable && pickerOpen) setPickerOpen(false);
      onCallbackAction!(bookingSelection);
      return;
    }
    if (displayState === DisplayState.Contactable && pickerOpen) {
      setPickerOpen(false);
      return;
    }
    if ([ DisplayState.Empty, DisplayState.Conflicted ].includes(displayState)) {
      mobileView && wrapperRef.current?.scrollIntoView({ block: "end" });
      setPickerOpen(prev => !prev);
      return;
    }
  }

  const onResume = () => {
    if (pickerOpen || !mobileView) return;
    mobileView && wrapperRef.current?.scrollIntoView({ block: "end" });
    !pickerOpen && setPickerOpen(true);
  }


  // rendering

  const isDesktop = useDisplayDesktop();
  const compactView = !mobileView && !isDesktop;

  const wrapperClasses = classNames("rla-book-choice", { "fixed": mobileView });
  const actionClasses = classNames("action-button", "bolder", { large: !compactView && !mobileView });
  const pickerWrapperClasses = classNames("rla-book-picker-float-wrapper", { mobile: mobileView });

  const disableAction = displayState === DisplayState.Processing ||
    (displayState === DisplayState.Bookable && blockCart) ||
    (displayState === DisplayState.OnEdit && !bookingSelection);

  const actionContentRendering = useCallback(() => {
    switch (displayState) {
      case DisplayState.Processing: return <IconLoader3 className="icon-loading" />;
      case DisplayState.Contactable: return intl.formatMessage(agencyTranslations.contact);
      case DisplayState.Bookable: return intl.formatMessage(bookingTranslations.book);
      default: return intl.formatMessage(bookingTranslations.availabilities)
    }
  }, [ displayState ]);

  if (mobileView)
    return (
     <>
       <div ref={wrapperRef}
            className={wrapperClasses}>

         <div className="book-price-resume">
           {(() => {
             if (priceLoading) return (
               <>
                 <Skeleton containerClassName="price" inline={true} width="55%" />
                 <Skeleton containerClassName="period" inline={true} width="85%" />
               </>);
             if (!priceLoading && !!bookingSelection) return (
               <>
                 <span className={classNames("price")}>
                   {bookingSelection.price === "onDemand"
                     ? intl.formatMessage(bookingTranslations.fullPriceOnDemand)
                     : print.price(bookingSelection.price.amount)
                   }
                 </span>
                 <span className={classNames("period", {conflict: displayState === DisplayState.Conflicted})}
                       onClick={onResume}>
                   {print.period(bookingSelection.period, true)}
                   <IconEdit/>
                 </span>
               </>);
             return intl.formatMessage(bookingTranslations.periodPlaceholder);
           })()}
         </div>

         <Button ref={reference}
                 {...getReferenceProps()}
                 type="button"
                 className={actionClasses}
                 onClick={onAction} disabled={disableAction}>
           {actionContentRendering()}
         </Button>

       </div>
       {pickerOpen &&
         <div ref={floating}
              {...getFloatingProps()}
              className={pickerWrapperClasses}
              style={{position: strategy, top: y ?? 0, left: x ?? 0}}>

           <div className="pickable-header">
             <Button type="button" className="link rounded-icon" onClick={() => setPickerOpen(false)}>
               <IconX/>
             </Button>
             <Button type="button" className="link small" onClick={onReset}>
               {intl.formatMessage(transDefs.clean)}
             </Button>
           </div>

           <div className="pickable-cal">
             <RangePickerController orientation={(mobileView ? "vertical" : "horizontal")}
                                    numberOfMonths={mobileView ? 1 : 2}
                                    minDate={bookingConfig?.period.start}
                                    maxDate={bookingConfig?.period.end}
                                    firstDayOfWeek={1}
                                    labelize={calendarLabelize}
                                    selectedRange={pickerRange}
                                    pickFocus={pickerFocus}
                                    onSelectedRangeChange={setPickerRange}
                                    onPickFocusChange={setPickerFocus}
                                    isDayBlocked={isDayBlocked} />
           </div>

         </div>
       }
     </>);

  return (
    <>
      <div ref={wrapperRef}
           className={wrapperClasses}>

        <button className="resume-period-wrapper"
                onClick={() => setPickerOpen(prev => !prev)}
                disabled={displayState === DisplayState.Processing}>
          <PeriodInputResume period={bookingSelection?.period} compactView={compactView}/>
        </button>

        <Button ref={reference}
                {...getReferenceProps()}
                type="button" className={actionClasses}
                onClick={onAction} disabled={disableAction}>
          {actionContentRendering()}
        </Button>

        {!!bookingSelection &&
          <>
            {(displayState === DisplayState.Conflicted) &&
              <span className="conflicted">
                <IconSquareRoundedXFilled />
                {intl.formatMessage(transDefs.conflict)}
              </span>
            }
            <BookPriceResume data={bookingSelection} />
            {!bookingSelection.bookable &&
              <span className="textual-ondemand">{intl.formatMessage(transDefs.onDemand)}</span>
            }
          </>
        }

      </div>
      {pickerOpen &&
        <div ref={floating}
          {...getFloatingProps()}
             className={pickerWrapperClasses}
             style={{ position: strategy, top: y ?? 0, left: x ?? 0 }}>

          <div className="pickable-header">
            <div className="priced-choice">
              {priceLoading &&
                <>
                  <Skeleton className="period" width="35%" />
                  <Skeleton className="price" width="50%" />
                </>
              }
              {(!priceLoading && !!bookingSelection) &&
                <>
                  <span className="period">
                    {intl.formatMessage(bookingTranslations.nightsPeriod, {
                      nightsCount: bookingSelection.period.end.diff(bookingSelection.period.start, "day")
                    })}
                  </span>
                  <span className="price">
                    {bookingSelection.price === "onDemand"
                      ? intl.formatMessage(bookingTranslations.fullPriceOnDemand)
                      : print.price(bookingSelection.price.amount)
                    }
                  </span>
                </>
              }
            </div>
            <PeriodInputResume period={bookingSelection?.period} />
          </div>

          <div className="pickable-cal">
            <RangePickerController orientation={(mobileView ? "vertical" : "horizontal")}
                                   numberOfMonths={mobileView ? 1 : 2}
                                   minDate={bookingConfig?.period.start}
                                   maxDate={bookingConfig?.period.end}
                                   firstDayOfWeek={1}
                                   labelize={calendarLabelize}
                                   selectedRange={pickerRange}
                                   pickFocus={pickerFocus}
                                   onSelectedRangeChange={setPickerRange}
                                   onPickFocusChange={setPickerFocus}
                                   isDayBlocked={isDayBlocked} />
          </div>

          <div className="pickable-footer">
            <Button type="button" className="link small" onClick={onReset}>
              {intl.formatMessage(transDefs.clean)}
            </Button>
            <Button type="button" className="secondary small" onClick={() => setPickerOpen(false)}>
              {intl.formatMessage(!!pickerRange ? transDefs.close : transDefs.cancel)}
            </Button>
          </div>

        </div>
      }
    </>);

}

export default BookingBox;
